<template>
    <div class="page" v-if="route_data.route_info">
      <div class="roll_back_line flex justify-between">
        <div @click="goBack" class="inline-block flex w-1/3 justify-start items-center go_back">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 18L8 12L14 6" stroke="#6c757d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div class="h-full label_roll_back">
            Back
          </div>
        </div>
      </div>
        <div class="title_line flex items-center justify-between w-full px-16">
            <h1 class="title">Print Preview</h1>
            <div class="route-action flex gap-10p pointer" @click="goPrint">
              Print page
            </div>
      </div>
        <div id="printPage">
          <div class="logo py-10 m-auto w-full">
            <img class="m-auto" v-if="logo_url" :src="logo_url" alt="logo">
            <img class="m-auto" v-else src="@/assets/images/logo.png" alt="logo">
          </div>
          <div class="body px-16">

          <div class="directions py-6 w-full flex flex-col items-center justify-between gap-15">
            <div class="w-full map text-center">
              <l-map
                  class="m-auto"
                  :zoom="zoom"
                  :center="center"
                  style="height: 450px; width: 800px"
              >
              <span v-if="getMarkers.length">
                <l-marker
                    v-for="(marker, index) in getMarkers"
                    :lat-lng="marker"
                    :key="index"
                    :icon="createIconWithText(index + 1)"
                >
                </l-marker>
              </span>
                <span v-if="getMultiWaypoints.length">
                <span
                    v-for="(points, index) in getMultiWaypoints"
                    :key="index"
                >
                    <l-polyline v-if="index > 0"
                                :lat-lngs="points"
                                :color="colors[index - 1]"

                    ></l-polyline>
                </span>

            </span>
                <l-tile-layer
                    :url="url" ></l-tile-layer>
              </l-map>
            </div>
          </div>
          <div class="legend">
            <h2>Legend</h2>
            <div class="flex items-center justify-start gap-20">
              <div class="flex items-center justify-between gap-15" v-for="(item, index) in getMarkers" :key="index">
                <div class="hidden">{{item}}</div>
                <div class="flex justify-between items-center gap-10p" v-if="index + 1 != getMarkers.length">
                <span class="legend_color" :style="{backgroundColor: colors[index]}">
                </span>
                  <span>
                  Route (leg #{{index + 1}})
                </span>
                </div>
              </div>
            </div>
          </div>
          <div class="route_details py-5">
            <div class="text-left p-2">
              <div class="title rounded bg-grey-lighter py-5 px-5">
                <h2 >Request details</h2>
              </div>
              <table class="border-table border-black mt-1 w-full">
                <tbody class="w-full">
                <tr>
                  <td class="border border-slate-300">
                    <strong>Company</strong>
                  </td>
                  <td class="border border-slate-300">{{user_data.company_name}}</td>
                  <td class="border border-slate-300">
                    <strong>Submission date</strong>
                  </td>
                  <td class="border border-slate-300">{{route_data.created_at | moment('DD-MM-YYYY')}}</td>
                </tr>
                <tr>
                  <td class="border border-slate-300">
                    <strong>Route ID</strong>
                  </td>
                  <td class="border border-slate-300">{{$route.params.id}}</td>
                  <td class="border border-slate-300">
                    <strong>Start time</strong>
                  </td>
                  <td class="border border-slate-300">{{route_data.route_info.time_start | moment('DD-MM-YYYY, HH:mm')}}</td>
                </tr>
                <tr>
                  <td class="border border-slate-300">
                    <strong>Route Name</strong>
                  </td>
                  <td class="border border-slate-300">{{route_data.name}}</td>
                  <td class="border border-slate-300">
                    <strong>End time</strong>
                  </td><td class="border border-slate-300">{{route_data.route_info.time_end | moment('DD-MM-YYYY, HH:mm')}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="route_details py-5">
            <div class="text-left p-2">
              <div class="title rounded bg-grey-lighter py-5 px-5">
                <h2 >Route details</h2>
              </div>
              <table class="border-table border-black mt-1 w-full">
                <tbody class="w-full">
                <tr>
                  <td class="border border-slate-300">
                    <strong>
                      Route name:
                    </strong>
                  </td>
                  <td class="border border-slate-300">
                    <strong>
                      Zane
                    </strong>
                  </td>
                </tr>
                <tr v-for="(location, index) in route_data.route_info.locations" :key="index">
                  <td class="border border-slate-300" v-if="index === 0">
                    Origin
                  </td>
                  <td class="border border-slate-300" v-else>
                    {{ index }} Destination
                  </td>
                  <td class="border border-slate-300">{{location.geo.postcode}}, {{location.geo.admin_district}}, {{location.geo.region}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="vehicle_details py-5">
            <div class="text-left p-2">
              <div class="title rounded bg-grey-lighter py-5 px-5">
                <h2 >Vehicle details</h2>
              </div>
              <table class="border-table border-black mt-1 w-full">
                <tbody class="w-full">
                <tr>
                  <td class="border border-slate-300">
                    <strong>
                      Name or Internal ID
                    </strong>
                  </td>
                  <td class="border border-slate-300">
                    <strong>
                      Reg. Number
                    </strong>
                  </td>
                  <td class="border border-slate-300">
                    <strong>
                      Make
                    </strong>
                  </td>
                  <td class="border border-slate-300">
                    <strong>
                      Type
                    </strong>
                  </td>
                  <td class="border border-slate-300">
                    <strong>
                      Height (Metres)
                    </strong>
                  </td>
                  <td class="border border-slate-300">
                    <strong>
                      Width (Metres)
                    </strong>
                  </td>
                  <td class="border border-slate-300">
                    <strong>
                      Length (Metres)
                    </strong>
                  </td>
                  <td class="border border-slate-300">
                    <strong>
                      Weight (Tonnes)
                    </strong>
                  </td>
                </tr>
                <tr v-for="(veh, i) in route_data.vehicle_info" :key="i">
                  <td class="border border-slate-300">
                    {{veh.name}}
                  </td>
                  <td class="border border-slate-300">
                    {{veh.registration_number}}
                  </td>
                  <td class="border border-slate-300">
                    {{veh.vehicle_make}}
                  </td>
                  <td class="border border-slate-300">
                    <span class='text-120px'>
                      {{ veh.vehicle_type ? parseTypeByKeys(veh.vehicle_type) : '-' }}
                    </span>
                  </td>
                  <td class="border border-slate-300">
                    {{veh.height}} m
                  </td>
                  <td class="border border-slate-300">
                    {{veh.width}} m
                  </td>
                  <td class="border border-slate-300">
                    {{veh.length}} m
                  </td>
                  <td class="border border-slate-300">
                    {{veh.weight}} t
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class='reach_text' v-if='react_text'>
            <div class=' py-5' v-html='react_text'></div>
          </div>
          <div class="w-full direction py-5">
            <div class="inner way" v-if="getMultiWaypointsText.length">
              <div class="flex-none p-4 border-b">
                <div class="flex justify-between items-center">
                  <strong class="text-xl font-medium">
                    Directions
                  </strong>
                </div>
                <div class="justify-between font-medium">
                  <div class="block text-lg text-orange-dark">
                    {{ secondsToDhms(timeDuration) }}
                    <span class="text-lg text-grey-dark">{{ parsedDistance.m }} miles ({{ parsedDistance.k }} km)</span>
                  </div>

                </div>
              </div>
              <ul >
                <li v-for="(item, index) in getMultiWaypointsText" :key="index">

                  <ul>
                    <li v-for="(el, i) in item" :key="i" class="py-5 bordered_b">
                      <div class="info-block flex" v-if="i == 0">
                        <div class="mark" :style="{backgroundColor: colors[index]}"></div>
                        <div class="data px-5 flex flex-col">
                          <div class="flex" v-if="
                        route_data.direction_data.length &&
                        route_data.direction_data[index] &&
                        route_data.direction_data[index].from
                        ">
                            <span class="l-title">
                              From:
                            </span>
                            <span>
                              {{route_data.direction_data[index].from.address}}
                            </span>
                          </div>
                          <div class="flex" v-if="
                        route_data.direction_data.length &&
                        route_data.direction_data[index] &&
                        route_data.direction_data[index].to
                        ">
                            <span class="l-title">
                              To:
                            </span>
                            <span>
                              {{route_data.direction_data[index].to.address}}
                            </span>
                          </div>
                          <div class="flex" v-if="
                            route_data.direction_data[index] &&
                            route_data.direction_data[index][0] &&
                            route_data.direction_data[index][0].from
                          ">
                            <span class="l-title">
                              From:
                            </span>
                            <span>
                              {{route_data.direction_data[index][0].from.address}}
                            </span>
                          </div>
                          <div class="flex" v-if="
                            route_data.direction_data[index] &&
                            route_data.direction_data[index][0] &&
                            route_data.direction_data[index][0].to
                        ">
                            <span class="l-title">
                              To:
                            </span>
                            <span>
                              {{route_data.direction_data[index][0].to.address}}
                            </span>
                          </div>

                          <div v-if="route_data.direction_data[index] && route_data.direction_data[index].duration">
                            <div class="flex">
                          <span class="l-title">
                          Distance:
                          </span>
                              <span>
                          {{distanceToString(route_data.direction_data[index].distance)}}
                          </span>
                            </div>
                            <div class="flex">
                          <span class="l-title">
                          Duration:
                          </span>
                              <span>
                          {{secondsToDhms(route_data.direction_data[index].duration)}}
                          </span>
                            </div>
                          </div>
                          <div v-if="
                        route_data.direction_data[index] &&
                        route_data.direction_data[index][0] &&
                        route_data.direction_data[index][0].duration">
                            <div class="flex">
                          <span class="l-title">
                          Distance:
                          </span>
                              <span>
                          {{distanceToString(route_data.direction_data[index][0].distance)}}
                          </span>
                            </div>
                            <div class="flex">
                          <span class="l-title">
                          Duration:
                          </span>
                              <span>
                          {{secondsToDhms(route_data.direction_data[index][0].duration)}}
                          </span>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="data">
                        <div class="flex items-center gap-15">
                          <div class="icon">
                        <span v-if="el.directions.includes('Turn left')">
                        <img src="@/assets/images/directions-svg/continue_left.svg" alt="logo">
                        </span>
                            <span v-if="el.directions.includes('Turn right')">
                        <img src="@/assets/images/directions-svg/continue_right.svg" alt="logo">
                        </span>
                            <span v-if="el.directions.includes('roundabout')">
                        <img src="@/assets/images/directions-svg/roundabout.svg" alt="logo">
                        </span>
                            <span v-if="el.directions.includes('Continue')">
                        <img src="@/assets/images/directions-svg/continue.svg" alt="logo">
                        </span>
                          </div>
                          <div class="flex gap-10p justify-between items-center">
                            <span>{{el.road}}</span>
                            <span>{{el.directions}}</span>
                          </div>

                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import L from "leaflet";
import {LMap, LMarker, LTileLayer, LPolyline} from "vue2-leaflet";
L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.3.4/dist/images/";
import "leaflet/dist/leaflet.css";
import moment from "moment";
import {createIconWithText, parseTypeByKeys} from "@/services/helpers";
import {baseErrHandler, getUserRoutesText} from "@/services/api";
export default {
  name: "Route",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline
  },
  data() {
    return {
      react_text: '',
      logo_url: null,
      route_data: [],
      user_data: JSON.parse(localStorage.getItem('user')),
      colors: {
        0: 'blue',
        1: 'red',
        2: 'green',
        3: 'yellow',
        4: 'blue',
        5: 'red',
        6: 'green',
        7: 'yellow',
        8: 'blue',
        9: 'red'
      },
      timeDuration: 0,
      distance: 0,
      parsedDistance: {
        k: null,
        m: null
      },
      waypoints: [],
      partDistance: [],
      zoom: 6,
      center: { lat: 53.495949, lng: -0.131529 },
      url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGF3c29uc2NvdHQiLCJhIjoiY2p4Ymp5YzhlMWU2eDNvbGdpM2o5endvYyJ9.dOMZ433p1ilQN22-njY91A",
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      traffic_main: null,
      add_vehicles: null,
      low_emission: null,
      ulez: null,
      london_control: null,
    }
  },
  computed: {
    ...mapGetters([
      'getMultiWaypointsText',
      'getColors',
      'getMarkers',
      'getMultiWaypoints'
    ]),
  },
  methods: {
    parseTypeByKeys,
    createIconWithText,
    ...mapMutations([
      'setMultiWaypointsText',
      'setMarkers',
      'setMultiWaypoints',
    ]),
    async goPrint() {
      return this.$htmlToPaper('printPage');
    },
    logoUrl() {
      this.logo_url = `${this.$http.baseUrl()}/` + JSON.parse(localStorage.getItem('supportData')).logo_url || null
    },
    goBack() {
      this.$router.go(-1);
    },
    estimatedEndTime(dtime) {
      return moment(dtime).add(this.timeDuration, 'seconds').format('DD-MM-YYYY, HH:mm');
    },
    async getRoute() {
      try {
        const route = await this.$http.getAuth(`${this.$http.apiUrl()}/routes/${this.$route.params.id}`) || [];
        this.route_data = route.data;

        let j = this;
        this.setMultiWaypoints([])
        this.setMultiWaypointsText([])
        await this.route_data.direction_data.forEach(direction => {
          if (direction[0]) {
            j.distance = j.distance + direction[0].distance;
            j.timeDuration = j.timeDuration + direction[0].duration;
            j.setPolyline(direction[0].geojson.coordinates)
            // return j.setMultiWaypointsText(direction[0].detail);
          } else {
            j.distance = j.distance + direction.distance;
            j.timeDuration = j.timeDuration + direction.duration;
            j.setPolyline(direction.geojson.coordinates)
            // return j.setMultiWaypointsText(direction.detail);
          }
        });
        await this.setMarkers();
        await this.addMarkers();
        this.parseDistance();
      } catch (e) {
        console.log(e)
      }
    },
    setPolyline(coordinates) {
      let items = [];
      coordinates.forEach(poi => {
        if (poi) {
          items.push(L.latLng(poi[1], poi[0]));
        }
      })
      this.setMultiWaypoints(items)
    },
    async addMarkers() {
      if (!this.route_data.route_info.locations || !this.route_data.route_info.locations.length) {
        return;
      }
      let j = this;
      await this.route_data.route_info.locations.forEach(location => {
        j.setMarkers(L.latLng(location.lat, location.lon));
      });
    },
    secondsToDhms(seconds) {
      seconds = Number(seconds);
      var d = Math.floor(seconds / (3600*24));
      var h = Math.floor(seconds % (3600*24) / 3600);
      var m = Math.floor(seconds % 3600 / 60);

      var dDisplay = d > 0 ? d + (" d, ") : "";
      var hDisplay = h > 0 ? h + (" h, ") : "";
      var mDisplay = m > 0 ? m + ("min") : "";
      return dDisplay + hDisplay + mDisplay;
    },
    parseDistance() {
      this.parsedDistance.k = Math.round(this.distance / 1000);
      this.parsedDistance.m = Math.round((this.distance / 1000) / 1.609344);
    },
    distanceToString(meters) {
      if(meters)
        return `${Math.round(meters / 1000)} Km (${Math.round((meters / 1000) / 1.609344)} miles)`;
      else return ''
    },
    async getRoutesText() {
      await baseErrHandler(async () => {
        const user = await getUserRoutesText();

        this.react_text = user?.data?.user?.routes_info;
      });
    },
  },
  async mounted() {
    await this.getRoute();
    await this.logoUrl();
    await this.getRoutesText();
  }
}
</script>

<style lang="scss" scoped>

.direction {
  .inner {

  }
}
.legend_color {
  width: 90px;
  height: 8px;
  border-radius: 5px;
}
</style>
